import React from "react"

import styled from "styled-components"
import { Navbar } from "../components/Navbar";

const Body = styled.div`
max-width: 700px;
margin: auto;
`

const HelpPage = () => (
  <div>
    <Navbar style={{backgroundColor: "#4a443f"}}/>
    <Body>
      <h1>Personvernerklæring</h1>
      <p>
        Her kommer det informasjon om hvordan vi håndterer dine data.
        Inntil videre er Kjør i en testfase, og kjøreloggene blir aktivt brukt for å kvalitetssikre tjenesten.
      </p>
    </Body>
  </div>
)

export default HelpPage
